
export const services = [
    {
        title: "Technology Enthusiast",
        icon: 'https://csgeeek.github.io/web.png',
    },
    {
        title: "Software Engineer",
        icon: 'https://csgeeek.github.io/web.png',
    },
    {
        title: "Photographer",
        icon: 'https://csgeeek.github.io/web.png',
    },
];

export const name = 'Yash Lunawat';

export const experiences = [
    {
        'company': 'E-Cell, Newton School of Technology and Rishihood University',
        'role': 'President',
        'duration': 'Jan 2024 - Apr 2024',
        'logo': 'https://media.licdn.com/dms/image/D560BAQFQObHjWNDlOw/company-logo_100_100/0/1708708316908?e=1729728000&v=beta&t=0OeCA_D6ezeZ_nMRJrOhtcWflCwBCuWjfCG53BlYRdg',
        'points': [
            'Directed a team of 20 members, ensuring effective coordination and execution of all E-Cell activities',
            'Organized and oversaw numerous events, including startup pitch competitions, hackathons, workshops, and guest lectures by industry experts.',
            'Managed the E-Cell budget effectively, ensuring financial sustainability.',


        ],
    },
    {
        'company': 'Neutron',
        'role': 'Core Committee',
        'duration': 'Jan 2024 - Apr 2024',
        'logo': 'https://media.licdn.com/dms/image/D560BAQGtarqF4XxjCg/company-logo_100_100/0/1711718548850/neutronfest_logo?e=1729728000&v=beta&t=YbDHaaBVDkzUFCzN5B4E9Ts2H9mfXXVhduc3nubqYGw',
        'points': [
            'Collaborated with fellow committee members to conceptualize and develop the overall theme, objectives, and structure of Neutron.',
            'Assisted in the planning and organization of various events, workshops, and competitions encompassing both technological and cultural domains.',
            'Actively contributed to marketing and promotional activities to generate buzz and attract participation from students, faculty, and external stakeholders.',
            'Worked closely with the event management team to address any challenges or obstacles encountered during the planning and execution phases.',
            'Engaged with participants and attendees to gather feedback and insights for future iterations of the fest, fostering a culture of continuous improvement and innovation.'
        ],
    },
    {
        'company': 'FrontRow',
        'role': 'Operations Intern',
        'duration': 'Sep 2021 - Mar 2022',
        'logo': 'https://media.licdn.com/dms/image/C4D0BAQEiAUINJpwJ8w/company-logo_100_100/0/1630521254910/frontrow_in_logo?e=1729728000&v=beta&t=VZYTx5RYo0CnWElyOAHIqUCmEkcRrm7sklO2wrkQlNU',
        'points': [
            'As A Manager of Operations of E-Sport in Frontrow, my work is to Look After all the Operations in that department and also to present it at higher lever.',
        ],
    },
]



export const EMAIL_JS_SERVICE_ID = 'service_90wb9t5';
export const EMAIL_JS_TEMPLATE_ID = 'template_8l87rew';
export const EMAIL_JS_PUBLIC_KEY = 'pHPjc9RGGkF66r86N';